import moment from 'moment-timezone';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import { AppointmentStatus } from '../firebase/firestore/documents/appointmentStatus';
import { Candidate, CandidateStatus, EngagedCandidate } from '../firebase/firestore/documents/candidate';
import { isCandidatePage } from '../utils/candidatesUtils';

export const isCandidateReadOnly = ({ computedStatus, dismissed, monetized }: Candidate): boolean => {
  return (
    !monetized ||
    dismissed ||
    !computedStatus?.status ||
    _includes(
      [CandidateStatus.InterestRevoked, CandidateStatus.Unknown, CandidateStatus.ContactRequestDeclined],
      computedStatus?.status,
    )
  );
};

export const isCandidateHireInReview = (computedStatus?: CandidateStatus): boolean => {
  return computedStatus === CandidateStatus.HireInReview;
};

const hasAcceptedAppointment = (candidate: Candidate) => candidate.appointment?.status === AppointmentStatus.accepted;
export const doesCandidateHasActiveAppointment = (candidate: Candidate): boolean => {
  if (!hasAcceptedAppointment(candidate)) {
    return false;
  }

  const appointmentStart = candidate.appointment?.toDate;
  return moment(appointmentStart).isAfter();
};

export const orderCandidatesByLastSeekerMessageAt = (candidates: Readonly<EngagedCandidate>[]): Readonly<EngagedCandidate>[] => {
  return _orderBy(
    candidates,
    [
      (candidate) => candidate.accountNotification?.lastSeekerMessageAt?.valueOf() ?? 0,
      (candidate) => candidate.accountNotification?.lastUpdatedAt?.valueOf() ?? 0,
    ],
    ['desc', 'desc'],
  );
};

export const isCandidateAlreadyHired = (candidate: Candidate): boolean =>
  candidate.computedStatus?.status === CandidateStatus.Hired ||
  candidate.computedStatus?.status === CandidateStatus.HiredOnAnotherPosition;

export const isCandidateUnhired = (candidate: Candidate): boolean => candidate.computedStatus?.status === CandidateStatus.Unhired;

export const isCandidateAlreadyDismissed = (candidate: Candidate): boolean =>
  candidate.dismissed || candidate.computedStatus?.status === CandidateStatus.Dismissed;

export const isCandidateFromFollowedPosition = (candidate: Candidate | undefined, recruiterId: string | undefined): boolean => {
  // istanbul ignore next
  if (isCandidatePage()) return true;
  if (candidate && candidate.assignedRecruiters && recruiterId) {
    return candidate.assignedRecruiters.includes(recruiterId);
  }
  return false;
};
