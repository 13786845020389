import HistoriesPresenter from './historiesPresenter';
import { useQuerySnapshot } from '../../../../../firebase/firestore/hooks';
import { Collections } from '../../../../../firebase/firestore/collections';
import { Query } from '../../../../../firebase/firestore/query/query';
import { CandidateAudit } from '../../../../../firebase/firestore/documents/candidateAudit';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { OrderByDirection } from '../../../../../firebase/firestore/query/criteria';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

export enum HistoryStateEnum {
  UNDEFINED,
  LOADING,
  READY,
}

export type HistoryState = {
  state: HistoryStateEnum;
  histories: HistoriesPresenter | undefined;
  initialized: boolean;
};

const useHistory = (): HistoryState => {
  const { currentUserProfile } = useCurrentUserProfile();
  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidate = selectedCandidate || selectedEngagedCandidate;
  const candidateAuditMessages = useQuerySnapshot<CandidateAudit>(Collections.CANDIDATE_AUDIT, [
    Query.field<CandidateAudit>('candidateId').equals(candidate?.id || ''),
    Query.field<CandidateAudit>('account').equals(currentUserProfile?.account),
    Query.orderBy<CandidateAudit>('timestamp', OrderByDirection.desc),
  ]);

  if (currentUserProfile && candidate && candidateAuditMessages) {
    if (candidateAuditMessages.length === 0 || !candidateAuditMessages.length) {
      return {
        state: HistoryStateEnum.UNDEFINED,
        histories: undefined,
        initialized: true,
      };
    }

    return {
      state: HistoryStateEnum.READY,
      histories: new HistoriesPresenter(candidateAuditMessages),
      initialized: true,
    };
  }

  return {
    state: HistoryStateEnum.LOADING,
    histories: undefined,
    initialized: false,
  };
};

export default useHistory;
