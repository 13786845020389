import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Switch, Text, useDisclosure } from '@chakra-ui/core';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';
import { IoMdRefresh } from 'react-icons/io';
import Bugsnag from '@bugsnag/js';
import isUndefined from 'lodash/isUndefined';
import { Header } from '../../app/topBar/Header';
import { ToDoCountViewSideBar } from './ToDoCountViewSideBar';
import RecruiterTodoListTableView from './RecruiterTodoLisTableView';
import { ActionEnum, RecruiterTodoListDataType } from './RecruiterTodoListType';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { ToDoData, ToDoDefault, ToDoList } from '../../firebase/firestore/documents/toDo';
import { ToDoCountViewTopBar } from './ToDoCountViewTopBar';
import colors from '../../styles/colors';
import { TablePaginationFooter } from '../../app/components/TablePaginationFooter';
// import { toDosActions } from './TodosActionsData';
import { RecruiterActionFormModal } from './RecruiterActionFormModal';
import { LanguageEvaluationModal } from './LanguageEvaluationModal';
import useLookupsByType from '../../app/hooks/useLookupsByType';
import { dashboardStyleCSS } from './DashboardViewStyle';
import { updateObject } from '../../utils/FormErrorUtils';
import { UserProfileType } from '../profile/ProfileTypes';

const DEFAULT_PREFERENCE_DAYS = 3;

export const DashboardHome = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const candidateToDoListViewRef = useRef<null | HTMLDivElement>(null);
  const [topBarHeight, setTopBarHeight] = useState(0);
  const { t } = useTranslation('toDoList');
  const headerInfoFromTranslation = {
    action: t('actionToTake'),
    lastStatus: t('lastStatus'),
    status: t('status'),
    locationAndPosition: t('locationAndPosition'),
    candidateName: t('candidate'),
  };
  const recruiterTodoListTableColumns: Column<RecruiterTodoListDataType>[] = [
    {
      Header: headerInfoFromTranslation.action,
      accessor: 'actionType',
    },
    {
      Header: headerInfoFromTranslation.lastStatus,
      accessor: 'lastStatus',
    },
    {
      Header: headerInfoFromTranslation.status,
      accessor: 'status',
    },
    {
      Header: headerInfoFromTranslation.locationAndPosition,
      accessor: 'location',
    },
    {
      Header: headerInfoFromTranslation.candidateName,
      accessor: 'candidateFullName',
    },
  ];

  const [toDOList, setToDoList] = useState<ToDoList>(ToDoDefault);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedToDoItem, setSelectedToDoItem] = useState<
    | {
        id: string;
        task: string;
        candidateName: string;
        candidateId: string;
        helpRequestedMessages?: {
          userType: string;
          message: string;
          timestamp: Date;
          name: string;
        }[];
        latestHelpRequestFor?: string;
        seekerHireDetailId?: string;
        hireDetailsRequestedAt?: string;
      }
    | undefined
  >();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLanguageEvaluationModelOpen,
    onOpen: onOpenLanguageEvaluationModel,
    onClose: onCloseLanguageEvaluationModel,
  } = useDisclosure();

  const [formattedTodoList, setFormattedTodoList] = useState<RecruiterTodoListDataType[]>([]);
  const [currentItems, setCurrentItems] = useState<RecruiterTodoListDataType[]>([]);
  const [filteredByStatusItems, setFilteredByStatusItems] = useState<RecruiterTodoListDataType[]>([]);

  const accountId = useStoreState((state) => state.app.accounts?.id);

  const { getToDo } = useStoreActions((actions) => actions.toDoList);
  const [openSideBar, setOpenSideBar] = useState(false);

  const topWrapperRef = useRef(null);
  const containerWithStickyNav = useRef<HTMLDivElement | null>(null);
  const topBarWithStickyBox = useRef<HTMLDivElement | null>(null);
  const dataTableContainer = useRef<HTMLDivElement | null>(null);

  const lookups = useLookupsByType('candidateTodo');
  const [userProfile, setUserProfile] = useState<UserProfileType>({});

  // const lookups = toDosActions;
  const candidateTodos = lookups[0]?.todo;
  const [isDataChanged, setIsDataChanged] = useState(true);
  const [disableLoader, setDisableLoader] = useState(false);
  const [filterByCompleted, setFilterByCompleted] = useState<boolean | undefined>(false);
  const [filterByStatus, setFilterByStatus] = useState<string>('');
  const { user } = useStoreState((s) => s.app);
  const { updateProfile } = useStoreActions((actions) => actions.profileManagement);

  useEffect(() => {
    if (user) {
      updateObject(userProfile, 'firstName', user.firstName, false);
      updateObject(userProfile, 'lastName', user.lastName, false);
      updateObject(userProfile, 'email', user.email, false);
      updateObject(userProfile, 'filterCandidateByDays', user.filterCandidateByDays || DEFAULT_PREFERENCE_DAYS, false);
      setUserProfile({ ...userProfile });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCurrentItems = (givenCurrentItems: RecruiterTodoListDataType[]) => {
    setCurrentItems(givenCurrentItems);
    candidateToDoListViewRef?.current?.scrollTo(0, 0);
  };

  const getActionTypeOfTodoItem = (status: string) => {
    switch (status) {
      case 'offerMade12HoursAgo':
      case 'offerMade24HoursAgo':
      case 'offerMade36HoursAgo':
      case 'offerMade48HoursAgo':
        return ActionEnum.OFFER;
      case 'bgCheckRequested24HoursAgo':
      case 'bgCheckRequested48HoursAgo':
      case 'bgCheckRequested72HoursAgo':
      case 'bgCheckFlaggedByCertnCandidates':
      case 'bgCheckNotProcessedByCertn24HoursAgoCandidates':
        return ActionEnum.BACKGROUND_CHECK;
      case 'hireFormRequested12HoursAgo':
      case 'hireFormRequested24HoursAgo':
      case 'hireFormRequested48HoursAgo':
      case 'hireFormRequested72HoursAgo':
      case 'hireFormCompleted24HoursAgo':
      case 'hireFormHelpRequested':
      case 'hireFormInReview':
        return ActionEnum.HIRE_FORM;
      case 'languageEvaluation':
        return ActionEnum.LANGUAGE_EVALUATION;
      case 'responseToCandidate':
      case 'dialogflow':
        return ActionEnum.CONVERSATIONAL_AI;
      default:
        return ActionEnum.OFFER;
    }
  };

  const formatData = (todoList: ToDoData[]): RecruiterTodoListDataType[] => {
    return todoList?.map((item) => ({
      id: item.recruiterActionId,
      actionType: getActionTypeOfTodoItem(item.status),
      location: item.businessName,
      candidateFullName: `${item.firstName} ${item.lastName}`,
      candidateId: item.id,
      position: item.position,
      status: t('toDo'),
      lastStatus: item.lastUpdate,
      title: item.status,
      description: item.status,
      customName: item.customName,
      latestHelpRequestedFor: item.latestHelpRequestedFor,
      hireDetailsRequestedAt: item.hireDetailsRequestedAt,
      helpRequestedMessages: item.helpRequestedMessages,
      seekerHireDetailId: item?.seekerHireDetailId,
      todoStatus: item.todoStatus ?? '',
    }));
  };

  const onGetTodoList = useCallback(
    async (account: string, givenFilterByCompleted: boolean, filterStatus: string) => {
      setIsLoading(true);
      const list = await getToDo({ accountId: account, needCompleted: givenFilterByCompleted });
      setIsLoading(false);
      setDisableLoader(true);
      const { data } = list;
      const sortedData = _orderBy(data, ['todoStatus'], ['desc']);
      setToDoList((prevState) => ({
        ...prevState,
        count: list.count,
        data: sortedData,
      }));
      setFormattedTodoList(formatData(sortedData));
      /* istanbul ignore next */
      if (filterStatus) {
        setFilteredByStatusItems(formatData(sortedData).filter((listItem) => listItem.title === filterStatus));
      }
      setIsDataChanged(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const sortOnDataChange = () => {
    const sortedData = _orderBy(toDOList.data, ['todoStatus'], ['desc']);

    setToDoList((prevState) => ({
      ...prevState,
      data: sortedData,
    }));
    setFormattedTodoList(formatData(sortedData));
    setIsDataChanged(false);
    setCurrentPage(currentPage);
  };

  const onUpdateProfile = async (userId: string) => {
    try {
      await updateProfile({ userID: userId, userProfile });
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  const onRefreshTodo = () => {
    setDisableLoader(false);
    /* istanbul ignore else */
    if (accountId && !isUndefined(filterByCompleted)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onGetTodoList(accountId, filterByCompleted, filterByStatus);
    }
  };

  useEffect(() => {
    if (user && user.needCompletedTodo) {
      setFilterByCompleted(user.needCompletedTodo);
    }
  }, [user]);

  useEffect(() => {
    if (user && filterByCompleted !== user?.needCompletedTodo) {
      /* istanbul ignore else */
      if (!isUndefined(filterByCompleted)) {
        updateObject(userProfile, 'needCompletedTodo', filterByCompleted, false);
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onUpdateProfile(user.id);
      setUserProfile({ ...userProfile });
    }
    // eslint-disable-next-linπenve react-hooks/exhaustive-deps

    onRefreshTodo();
    sortOnDataChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByCompleted]);

  /* istanbul ignore next */
  const handleAction = (
    id: string,
    task: string,
    candidateName: string,
    candidateId: string,
    helpRequestedMessages?: {
      userType: string;
      message: string;
      timestamp: Date;
      name: string;
    }[],
    latestHelpRequestFor?: string,
    seekerHireDetailId?: string,
    hireDetailsRequestedAt?: string,
  ) => {
    setSelectedToDoItem({
      id,
      task,
      candidateName,
      candidateId,
      helpRequestedMessages,
      latestHelpRequestFor,
      seekerHireDetailId,
      hireDetailsRequestedAt,
    });
    if (task === 'languageEvaluation') {
      onOpenLanguageEvaluationModel();
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (accountId && !isUndefined(filterByCompleted)) {
      /* eslint-disable @typescript-eslint/no-floating-promises */
      onGetTodoList(accountId, filterByCompleted, filterByStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, onGetTodoList]);

  useEffect(
    () => {
      if (isDataChanged) {
        sortOnDataChange();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDataChanged],
  );

  /* istanbul ignore next */
  useEffect(() => {
    const updateHeight = () => {
      if (topBarWithStickyBox.current) {
        setTopBarHeight(topBarWithStickyBox.current.offsetHeight);
      }
    };

    const observer = new ResizeObserver(() => {
      updateHeight();
    });

    if (topBarWithStickyBox.current) {
      observer.observe(topBarWithStickyBox.current);
      updateHeight();
    }
  }, []);

  // istanbul ignore next
  const handleScroll = () => {
    if (candidateToDoListViewRef.current && containerWithStickyNav.current) {
      // Access scrollTop, scrollHeight, clientHeight, etc. as needed
      if (candidateToDoListViewRef.current.scrollTop >= containerWithStickyNav.current.offsetTop - 50) {
        containerWithStickyNav.current.classList.add('sticky');
        topBarWithStickyBox.current?.classList.remove('stickyTop');
      } else {
        containerWithStickyNav.current.classList.remove('sticky');
        topBarWithStickyBox.current?.classList.add('stickyTop');
      }
    }
  };

  // istanbul ignore next
  const handleDataChanged = () => {
    setIsDataChanged(true);
  };

  const handleFilterByStatus = (status: string) => {
    setFilterByStatus(status);
    setCurrentPage(1);
    dataTableContainer.current?.scrollTo(0, 0);
    /* istanbul ignore next */
    if (status) {
      setFilteredByStatusItems(formattedTodoList.filter((list) => list.title === status));
    }
  };

  return (
    <Box backgroundColor={colors.antiFlashWhite} ref={topWrapperRef}>
      <Header showMenu showPageTitle={t('toDoList:pageTitle')} />
      <Box css={dashboardStyleCSS} style={{ paddingTop: `${topBarHeight}px` }}>
        <Flex
          direction="column"
          height={`calc(100vh - ${topBarHeight + 68}px)`}
          overflowY="auto"
          ref={candidateToDoListViewRef}
          onScroll={handleScroll}
        >
          <Flex ref={topBarWithStickyBox} className="stickyTop" bg="#fff" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <ToDoCountViewTopBar
                count={toDOList.count}
                onOpenSideBar={() => setOpenSideBar(!openSideBar)}
                openSideBar={openSideBar}
              />

              <Button
                onClick={onRefreshTodo}
                size="md"
                fontSize="sm"
                colorScheme="gray"
                leftIcon={<IoMdRefresh size={20} />}
                data-testid="refreshBtn"
                mr={4}
                variant="outline"
              >
                {t('jobPosition:refresh')}
              </Button>

              <Flex direction="row" alignItems="center" flexWrap="nowrap">
                <Text htmlFor="showCompletedItems" mr={2}>
                  {t('toDoList:showCompletedItems')}
                </Text>
                <Switch
                  isChecked={filterByCompleted}
                  size="sm"
                  id="filterByCompleted"
                  data-testid="filterByCompleted"
                  mr={3}
                  onChange={(e) => {
                    setFilterByCompleted(e.target.checked);
                    setCurrentPage(1);
                  }}
                />
              </Flex>
            </Flex>
            <Box className="header-pagination">
              <TablePaginationFooter
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                finalListOfItems={filterByStatus ? filteredByStatusItems : formattedTodoList}
                setCurrentItemsList={handleCurrentItems}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                showingResultsTranslation={(variable: string) => t('toDoList:showingResult', variable)}
              />
            </Box>
          </Flex>
          <Box height="100%">
            <Flex height="100%">
              {openSideBar && (
                <Box w="20%" height="100%" overflowY="auto" bg="#fff">
                  <ToDoCountViewSideBar
                    count={toDOList.count}
                    filterByStatus={filterByStatus}
                    setFilterByStatus={handleFilterByStatus}
                  />
                </Box>
              )}
              <Box
                ref={dataTableContainer}
                height="100%"
                overflowY="auto"
                w={openSideBar ? '80%' : '100%'}
                p={openSideBar ? '0px' : '0px 12px 12px 12px'}
              >
                <Box bg="#fff">
                  {candidateTodos && (
                    <RecruiterTodoListTableView
                      isLoading={isLoading && !disableLoader}
                      handleAction={handleAction}
                      candidateTodos={candidateTodos}
                      columns={recruiterTodoListTableColumns}
                      data={currentItems}
                      onCallToDoAPI={handleDataChanged}
                      accountId={accountId}
                      setToDoList={setToDoList}
                    />
                  )}
                  <TablePaginationFooter
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    finalListOfItems={filterByStatus ? filteredByStatusItems : formattedTodoList}
                    setCurrentItemsList={handleCurrentItems}
                    showItemsPerPage
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    showingResultsTranslation={(variable: string) => t('toDoList:showingResult', variable)}
                  />
                  {selectedToDoItem && /* istanbul ignore next  */ isOpen && /* istanbul ignore next  */ candidateTodos && (
                    /* istanbul ignore next  */
                    <RecruiterActionFormModal
                      selectedToDoItem={selectedToDoItem}
                      candidateTodos={candidateTodos}
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                  )}
                  {selectedToDoItem && /* istanbul ignore next  */ isLanguageEvaluationModelOpen /* istanbul ignore next  */ && (
                    <LanguageEvaluationModal
                      isOpen={isLanguageEvaluationModelOpen}
                      onClose={onCloseLanguageEvaluationModel}
                      currentCandidateId={selectedToDoItem.candidateId}
                      currentTask={selectedToDoItem.task}
                      currentDocId={selectedToDoItem.id}
                      isRenderedBy="DashboardHome"
                    />
                  )}
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
