import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  Select,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  Switch,
  useToast,
  Textarea,
} from '@chakra-ui/core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdCheckBoxOutlineBlank, MdCheckBox, MdOutlineRadioButtonUnchecked, MdOutlineRadioButtonChecked } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { IoClose } from 'react-icons/io5';
import ResizeTextarea from 'react-textarea-autosize';
import { CorrectAnswerDeterminationType, JobPosition, MultipleChoiceQuestion } from '../PositionType';
import colors from '../../../../styles/colors';
import { errorFormat, FormattedError, getErrorMessage, hasErrorMessage } from '../../../../utils/FormErrorUtils';

export type MultipleChoiceQuestionsCardProps = {
  jobPositionData: JobPosition;
  addMultipleChoiceQuestion: (question: MultipleChoiceQuestion, questionEditMode: number | undefined) => void;
  deleteMultipleQuestionsQuestion: (questionId: number) => void;
  questionToEdit: MultipleChoiceQuestion;
  questionToEditMode: number | undefined;
  onRefreshState: () => void;
};

interface InputData {
  id: number;
  value: string;
}

export const MultipleChoiceQuestionsCard = ({
  addMultipleChoiceQuestion,
  questionToEdit,
  questionToEditMode,
}: MultipleChoiceQuestionsCardProps): JSX.Element => {
  const { t } = useTranslation();
  const toast = useToast();
  const [numberOfOptions, setNumberOfOptions] = useState<string>('1');
  const [atleast, setAtlest] = useState<string | undefined>('1');
  const [selectAnswers, setSelectAnswers] = useState(false);
  const [knockout, setKnockout] = useState(false);
  const [errorOptionsList, setErrorOptionsList] = useState(false);
  const [answersList, setAnswersList] = useState<number[]>([]);
  const [questions, setQuestion] = useState<string>('');
  const [questionEditMode, setQuestionEditMode] = useState<number | undefined>(undefined);
  const [correctAnswerDeterminationType, setCorrectAnswerDeterminationType] = useState<
    CorrectAnswerDeterminationType | undefined
  >(CorrectAnswerDeterminationType.IS_EQUAL);
  const [queFormErrors, setQueFormErrors] = useState<FormattedError[]>([]);
  const textAreaRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [inputs, setInputs] = useState<InputData[]>([
    { id: 1, value: '' },
    { id: 2, value: '' },
  ]);

  /* istanbul ignore next */
  const addOption = () => {
    const newItem = {
      id: inputs[inputs.length - 1].id + 1,
      value: '',
    };
    setInputs((prevInputs) => [...prevInputs, newItem]);
  };
  const removeOption = (id: number, label: number) => {
    let newAnswerList = [...answersList];
    if (newAnswerList.includes(label)) {
      newAnswerList = newAnswerList.filter((answer) => answer !== label);
    }
    setAnswersList(newAnswerList);
    setInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const onGetOptionTypeByPriority = (priority: number) => {
    switch (priority) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'E';
      /* istanbul ignore next */
      default:
        return '';
    }
  };

  const editQuestion = (questionId: number | undefined, ques: MultipleChoiceQuestion) => {
    setQuestion(ques.question);
    setNumberOfOptions(ques.totalAnswer.toString());
    setCorrectAnswerDeterminationType(ques?.correctAnswerIf);
    setKnockout(ques.knockout);
    const options: InputData[] = [];
    const ansList: number[] = [];
    ques.options.forEach((q, index) => {
      const label = onGetOptionTypeByPriority(q.priority);
      const o = { id: index, value: q.text, label };
      if (q.isPreferredAnswer) {
        ansList.push(index);
      }
      options.push(o);
    });
    setInputs(options);
    setAnswersList(ansList);
    setAtlest(ques?.shouldMatchAtLeast?.toString());
    setQuestionEditMode(questionId);
  };

  useEffect(() => {
    editQuestion(questionEditMode, questionToEdit);
    setQuestionEditMode(questionToEditMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionToEdit, questionToEditMode]);

  const optionSchema = Joi.object({
    text: Joi.string()
      .required()
      .messages({
        'string.empty': t('createJobPosition:optionNotEmpty'),
      }),
    isPreferredAnswer: Joi.boolean(),
    priority: Joi.number(),
  });

  const multipleQuestionSchema = Joi.object().keys({
    question: Joi.string()
      .trim(true)
      .max(500)
      .required()
      .messages({
        'string.max': t('createJobPosition:questionMaxLetterRequired', { count: 500 }),
        'string.empty': t('createJobPosition:questionNotEmpty'),
      }),
    knockout: Joi.boolean().optional(),
    options: Joi.array().items(optionSchema),
    isActive: Joi.boolean().optional(),
    correctAnswerIf: Joi.string().optional(),
    totalAnswer: Joi.number().required(),
    shouldMatchAtLeast: Joi.number().optional(),
  });

  const queFormValidation = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    const { error } = multipleQuestionSchema.validate(formData);

    if (error) {
      setQueFormErrors(errorFormat(error.details));
      return false;
    }
    if (answersList.length < parseInt(numberOfOptions, 10)) {
      setErrorOptionsList(true);
      toast({
        title: t('createJobPosition:optionsRequired', {
          numberOfOptions,
        }),
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    setErrorOptionsList(false);
    setQueFormErrors([]);
    return true;
  };

  const clearFormData = () => {
    setQuestion('');
    setNumberOfOptions('1');
    setCorrectAnswerDeterminationType(CorrectAnswerDeterminationType.IS_EQUAL);
    setKnockout(false);
    setInputs([
      { id: 1, value: '' },
      { id: 2, value: '' },
    ]);
    setAnswersList([]);
    setQuestionEditMode(undefined);
    setErrorOptionsList(false);
  };

  const handleInputChange = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
    // istanbul ignore else
    if (event.target.value.length <= 120) {
      const newInputs = inputs.map((input) => (input.id === id ? { ...input, value: event.target.value } : input));
      setInputs(newInputs);
    }
  };

  // eslint-disable-next-line consistent-return
  const createQuestion = () => {
    const options: { text: string; isPreferredAnswer: boolean; priority: number }[] = [];
    inputs.forEach((i, index) => {
      options.push({
        text: i.value.trim(),
        isPreferredAnswer: answersList.includes(index),
        priority: index,
      });
    });
    const multipleChoiseQuestionData: MultipleChoiceQuestion = {
      question: questions.trim(),
      knockout,
      totalAnswer: parseInt(numberOfOptions, 10),
      options,
      shouldMatchAtLeast:
        CorrectAnswerDeterminationType.AT_LEAST === correctAnswerDeterminationType && atleast
          ? parseInt(atleast, 10)
          : /* istanbul ignore next */ undefined,
      correctAnswerIf: correctAnswerDeterminationType,
    };
    const validate = queFormValidation(multipleChoiseQuestionData);
    if (!validate) {
      return false;
    }
    addMultipleChoiceQuestion(multipleChoiseQuestionData, questionEditMode);
    setQuestionEditMode(undefined);
    clearFormData();
  };

  const onGetIcon = (label: number) => {
    if (parseInt(numberOfOptions, 10) === 1) {
      if (answersList.includes(label)) {
        return <MdOutlineRadioButtonChecked style={{ color: colors.blue[500] }} />;
      }
      return <MdOutlineRadioButtonUnchecked style={{ color: colors.blue[500] }} />;
    }
    if (answersList.includes(label)) {
      return <MdCheckBox style={{ color: colors.blue[500] }} />;
    }
    return <MdCheckBoxOutlineBlank style={{ color: colors.blue[500] }} />;
  };

  return (
    <Box marginTop="20px">
      <Flex>
        <Text fontSize="14px" display="flex" alignItems="center" fontWeight="700">
          {t('createJobPosition:pleaseSelect')}
        </Text>
        <FormControl w="6%" marginLeft="8px">
          <Select
            fontSize="14px"
            fontWeight="700"
            className="filled"
            value={numberOfOptions}
            data-testid="numberOfOptions"
            onChange={(e) => {
              setAnswersList([]);
              setErrorOptionsList(false);
              setNumberOfOptions(e.target.value);
            }}
          >
            {inputs.map((e, index) => (
              <option value={(index + 1).toString()} key={e.id}>
                {index + 1}
              </option>
            ))}
          </Select>
        </FormControl>
        <Text marginLeft="8px" display="flex" alignItems="center" fontSize="14px" fontWeight="700">
          {t('createJobPosition:bestOptions')}
        </Text>

        <FormControl w="20%" marginLeft="8px">
          <Select
            fontSize="14px"
            fontWeight="700"
            className="filled"
            value={correctAnswerDeterminationType}
            data-testid="correctAnswerDeterminationType"
            onChange={(e) => {
              setErrorOptionsList(false);
              setCorrectAnswerDeterminationType(e.target.value as CorrectAnswerDeterminationType);
            }}
          >
            <option value={CorrectAnswerDeterminationType.IS_EQUAL}>
              {t('createJobPosition:multipleChoiceQuestion.IS_EQUAL')}
            </option>
            {parseInt(numberOfOptions, 10) !== 1 && (
              <option value={CorrectAnswerDeterminationType.HAS_ANSWER}>
                {t('createJobPosition:multipleChoiceQuestion.HAS_ANSWER')}
              </option>
            )}
            <option value={CorrectAnswerDeterminationType.DOES_NOT_HAVE_ANSWER}>
              {t('createJobPosition:multipleChoiceQuestion.DOES_NOT_HAVE_ANSWER')}
            </option>
            <option value={CorrectAnswerDeterminationType.AT_LEAST}>
              {t('createJobPosition:multipleChoiceQuestion.AT_LEAST')}
            </option>
          </Select>
        </FormControl>

        {CorrectAnswerDeterminationType.AT_LEAST === correctAnswerDeterminationType && (
          <FormControl w="6%" marginLeft="8px">
            <Select
              fontSize="14px"
              fontWeight="700"
              className="filled"
              value={atleast}
              data-testid="atleast"
              onChange={(e) => setAtlest(e.target.value)}
            >
              {parseInt(numberOfOptions, 10) === 1 ? (
                <option value="1">1</option>
              ) : (
                inputs.map((e, index) => (
                  <option value={(index + 1).toString()} key={e.id}>
                    {index + 1}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
        )}
        {CorrectAnswerDeterminationType.AT_LEAST === correctAnswerDeterminationType && (
          <Text fontSize="14px" display="flex" alignItems="center" marginLeft="8px" fontWeight="700">
            {t('createJobPosition:of')}
          </Text>
        )}
        <FormControl w="20%" marginLeft="8px">
          <Menu isOpen={selectAnswers}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              background="#f5f8fa"
              border={errorOptionsList ? '1px solid red' : 'unset'}
              fontSize="14px"
              fontWeight="700"
              height="48px"
              display="flex"
              alignItems="center"
              data-testid="selectAnswerBtn"
              onClick={() => setSelectAnswers(true)}
            >
              {answersList.length === 0
                ? t('createJobPosition:selectOptions')
                : answersList.map((num) => String.fromCharCode('A'.charCodeAt(0) + num)).join(', ')}
            </MenuButton>
            <MenuList>
              {inputs.map((e, index) => (
                <MenuItem
                  _focus={{ background: '#ffffff' }}
                  key={e.id}
                  data-testid={`selectAnswerBtn_${e.id}`}
                  onClick={() => {
                    let newAnswerList = [...answersList];
                    /* istanbul ignore next */
                    if (parseInt(numberOfOptions, 10) === 1) {
                      if (CorrectAnswerDeterminationType.AT_LEAST !== correctAnswerDeterminationType) {
                        newAnswerList = [index];
                      } else if (newAnswerList.includes(index)) {
                        newAnswerList = newAnswerList.filter((answer) => answer !== index);
                      } else {
                        newAnswerList.push(index);
                      }
                    } else if (newAnswerList.includes(index)) {
                      newAnswerList = newAnswerList.filter((answer) => answer !== index);
                    } else if (parseInt(numberOfOptions, 10) !== newAnswerList.length) {
                      newAnswerList.push(index);
                    } else if (CorrectAnswerDeterminationType.AT_LEAST === correctAnswerDeterminationType) {
                      newAnswerList.push(index);
                    }

                    setAnswersList(newAnswerList);
                  }}
                >
                  {onGetIcon(index)}
                  <Text fontSize="14px" mt="2px" ml="10px" fontWeight="700">
                    {onGetOptionTypeByPriority(index)}
                  </Text>
                </MenuItem>
              ))}

              <MenuItem display="flex" justifyContent="end" _focus={{ background: '#ffffff' }}>
                <Button colorScheme="blue" data-testid="doneBtn" onClick={() => setSelectAnswers(false)}>
                  {t('createJobPosition:done')}
                </Button>
              </MenuItem>
            </MenuList>
          </Menu>
        </FormControl>
      </Flex>
      <Flex mt="14px">
        <Text fontSize="14px" color="#607D8B">
          {t('createJobPosition:hintText')}
        </Text>
        {/* <Tooltip placement="top" shouldWrapChildren label={t('createJobPosition:hintText')}> */}
        {/*  <BsFillInfoCircleFill /> */}
        {/* </Tooltip> */}
      </Flex>
      <Box mt="8px" w="80%">
        <FormControl isInvalid={hasErrorMessage(queFormErrors, 'question')} ref={textAreaRef}>
          <Textarea
            variant="filled"
            minH="unset"
            maxH="120px"
            minRows={1}
            maxRows={5}
            as={ResizeTextarea}
            placeholder="Type your question here"
            value={questions}
            onChange={(e) => {
              // istanbul ignore else
              if (e.target.value.length <= 350) {
                setQuestion(e.target.value);
              }
            }}
            data-testid="questions"
            resize="none"
          />
          <FormErrorMessage>{getErrorMessage(queFormErrors, 'question')}</FormErrorMessage>
        </FormControl>
      </Box>
      <Box>
        {inputs.map((input, index) => (
          <Flex w="80%" alignItems="center" mt="12px" key={input.id}>
            <FormControl isInvalid={hasErrorMessage(queFormErrors, `options`)}>
              <InputGroup>
                <InputLeftAddon>
                  <Text mr="10px" fontsize="14px">
                    {onGetOptionTypeByPriority(index)}
                  </Text>
                  {onGetIcon(index)}
                </InputLeftAddon>
                <Input
                  variant="filled"
                  minH="47px"
                  minRows={1}
                  maxRows={2}
                  height="47px !important"
                  as={ResizeTextarea}
                  placeholder={`Option ${onGetOptionTypeByPriority(index)}`}
                  data-testid={`Option-${onGetOptionTypeByPriority(index)}`}
                  value={input.value}
                  onChange={handleInputChange(input.id)}
                  resize="none"
                />
              </InputGroup>
              {index === inputs.length - 1 && <FormErrorMessage>{getErrorMessage(queFormErrors, `options`)}</FormErrorMessage>}
            </FormControl>
            {index === 0 || index === 1 ? (
              <Box ml="6" cursor="pointer">
                <IoClose color="#fff" />
              </Box>
            ) : (
              <Box ml="6" cursor="pointer">
                <IoClose
                  color={colors.red[500]}
                  data-testid={`removeOption-${index}`}
                  onClick={() => removeOption(input.id, index)}
                />
              </Box>
            )}
          </Flex>
        ))}
        {inputs.length < 5 && (
          <Text
            width="fit-content"
            mt={4}
            textDecoration="underline"
            _hover={{ color: colors.blue[500] }}
            cursor="pointer"
            color="#607D8B"
            fontSize={16}
            fontWeight={700}
            onClick={addOption}
            data-testid="addOptionBtn"
          >
            {t('createJobPosition:addOption')}
          </Text>
        )}
      </Box>
      <Box mt="10" d="inline-block">
        <FormControl>
          <FormLabel htmlFor="autoDismiss" style={{ pointerEvents: 'none' }}>
            {t('createJobPosition:questionAutoDismissLabel')}
          </FormLabel>
          <Switch
            id="autoDismiss"
            isChecked={knockout}
            data-testid="Knockout-question"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setKnockout(e.target.checked)}
          />
        </FormControl>
      </Box>
      <Flex justifyContent="end">
        <Button
          size="sm"
          colorScheme="blue"
          // disabled={questions.text.length < 9}
          data-testid="addQuestionBtn"
          onClick={() => createQuestion()}
        >
          {t('createJobPosition:confirmBtn')}
        </Button>
      </Flex>
    </Box>
  );
};
