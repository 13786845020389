export default {
  notAuthorized: {
    notAuthorizedMsg: 'Not Authorized to view this page. Please contact your administration.',
    goToHomeBtn: ' Go Back To Home Page',
  },
  administrationSideBarMenuItems: {
    jobListingUrl: 'Job Listing URL ',
    uploadCompanyLogo: 'Upload Company Logo',
    certnIntegration: 'Certn Integration',
    positionManagement: 'Position Management',
    lowAvailability: 'Team Low Availability',
    recruiterManagement: 'Recruiter Management',
    appointmentCalendarLabel: 'Calendar',
    configuration: 'Configuration',
    unresponsivePeriod: 'Unresponsive Candidates Management',
    languageEvaluationPhrasesLabel: 'Language Evaluation',
    videoGallery: 'Video Gallery',
    webHookURL: 'Webhook Endpoint',
  },
  calendar: {
    sidebarTitle: 'Recruiters',
    positionTitle: 'Positions',
    all: 'All',
    followed: 'Followed',
    sidebarRecruiterSearchPlaceholder: 'Search recruiters',
    sidebarPositionSearchPlaceholder: 'Search positions',
    sidebarPositionClearAll: 'Clear all',
    toolTipWarningMessage: `To view {{recruiterName}}'s interviews, you must uncheck another recruiter.`,
    toolTipWarningMessageSnackBar: `To view interviews, you must uncheck another recruiter.`,
    backBtnText: 'Back',
  },
  hireFormAPIKey: {
    label: 'API Keys',
    subDescription: 'Copy API key for hire form',
    copyKeyBtnLabel: 'Copy Key',
    generateNewAPIKey: 'Generate New API Key',
    copiedLabel: 'Hire Form API token copied',
    renewedSuccessMessage: 'New API key generated successfully',
    alertModal: {
      title: 'Generate New API Token',
      description:
        'This will generate the New API Key which needs to be used throughout the Hire flow system. Do you want to Continue ?',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    alertModalCopy: {
      title: 'The API Token is limited to a single copy.',
      copyBtn: 'Copy Code',
    },
  },
  modernHire: {
    sidebarTitle: 'Competency Evaluation',
    form: {
      userName: 'Username',
      password: 'Password',
      modernHireSubDomain: 'Modern Hire Sub Domain',
      submit: 'Submit',
    },
  },
  languageEvaluation: {
    cardTitle: 'Language Evaluation',
    selectLanguage: 'Select Language',
    french: 'French',
    english: 'English',
    phraseTitle: 'Phrase Title',
    phrase: 'Phrase',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
    deletePhraseTitle: 'Delete Phrase',
    deletePhraseDetail: 'This phrase will be permanently deleted. This action cannot be undone.',
    confirm: 'Confirm',
    cancel: 'Cancel',
    phraseLabelRequired: 'Phrase label is required',
    phraseDescriptionRequired: 'Phrase is required',
  },
  webHookUrl: {
    title: 'Hired Candidate Webhook Endpoint',
    subtitle:
      "You can set the endpoint URL for the Hired Candidate API here. This endpoint is where we'll send a candidate's details once they're hired.",
    inputPlaceHolder: 'Enter URL Endpoint',
    successMsg: 'Webhook URL updated successfully.',
    errorMsg: 'Webhook URL could not be updated.',
    validUrl: 'Only valid URL formats are accepted.',
  },
};
