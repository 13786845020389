import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, IconButton, Text } from '@chakra-ui/core';
import { CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';
import { AudioReview } from '../messaging/seeker/candidate/audioReview/AudioReview';
import useCandidate from '../../app/components/useCandidate';

export type LanguageEvaluationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  currentCandidateId: string;
  isRenderedBy?: string;
  currentTask?: string;
  currentDocId?: string;
};

export const LanguageEvaluationModal = ({
  isOpen,
  onClose,
  currentCandidateId,
  isRenderedBy,
  currentDocId,
  currentTask,
}: LanguageEvaluationModalProps): JSX.Element => {
  const currentCandidate = useCandidate(currentCandidateId);
  const { t } = useTranslation();
  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl" scrollBehavior="inside">
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <Text fontWeight="bold">{t('common:tabs.audioRecording')}</Text>
          </ModalHeader>
          <ModalCloseButton
            as={IconButton}
            icon={<CloseIcon boxSize={5} />}
            aria-label="Close modal"
            style={{
              cursor: 'pointer',
              backgroundColor: colors.white,
              borderRadius: '50%',
              border: `1px solid ${colors.gray[300]}`,
              padding: 20,
            }}
            _hover={{ background: `${colors.gray[100]} !important` }}
          />

          <ModalBody borderTop={`1px solid ${colors.gray[100]}`} minHeight="50vh" overflow="auto">
            {currentCandidate && (
              <AudioReview
                candidate={currentCandidate}
                onCloseLangModel={onClose}
                isRenderedBy={isRenderedBy}
                currentDocId={currentDocId}
                currentTask={currentTask}
                currentCandidateId={currentCandidateId}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
