import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, Text, Stack, Checkbox, Flex, useDisclosure, Badge } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import isUndefined from 'lodash/isUndefined';
import { SearchAndSortTypes } from '../filters/seekersFiltersContextValueType';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import { useStoreActions } from '../../../../models/hooks';
import { Requisition, RequisitionStatus } from '../../../../firebase/firestore/documents/requisition';
import colors from '../../../../styles/colors';
import ViewSingleRequisitionDetail from './ViewSingleRequisitionDetail';

type GroupedRequisitions = {
  [status in RequisitionStatus]: Requisition[];
};

export const RequisitionsList = (): JSX.Element => {
  const { t } = useTranslation();
  const { getPositionRequisition } = useStoreActions((actions) => actions.positionManagement);
  const [allRequisitionsByStatus, setAllRequisitionsByStatus] = useState<GroupedRequisitions | undefined>();
  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();
  const { positionId } = useParams<{ positionId: string }>();

  const [currentlyClickedRequisitionId, setCurrentlyClickedRequisitionId] = useState('');
  const { isOpen: isOpenRequisition, onOpen: onOpenRequisition, onClose: onCloseRequisition } = useDisclosure();

  const handleRequisitionModalOpen = useCallback(
    (clickedRequisitionId: string) => {
      setCurrentlyClickedRequisitionId(clickedRequisitionId);
      onOpenRequisition();
    },
    [setCurrentlyClickedRequisitionId, onOpenRequisition],
  );

  const onRequisitionModalClose = () => {
    onCloseRequisition();
    setCurrentlyClickedRequisitionId('');
  };

  const clearRequisitionsInitially = () => {
    searchAndSortTypes.requisitionList = [];
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSortTypes });
  };

  useEffect(() => {
    clearRequisitionsInitially();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises,@typescript-eslint/no-use-before-define
    getRequisitionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequisitionList = async () => {
    const allRequisitionsList = await getPositionRequisition({ positionId });
    const groupedByStatusRequisitions = groupBy(allRequisitionsList, 'status') as GroupedRequisitions;
    setAllRequisitionsByStatus(groupedByStatusRequisitions);
  };

  const onChangeSearchAndSort = (searchAndSort: SearchAndSortTypes) => {
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSort });
  };

  const getRequisitionColorFromStatus = (reqStatus: RequisitionStatus) => {
    switch (reqStatus) {
      case RequisitionStatus.OPEN:
        return colors.green[400];

      case RequisitionStatus.PROCESSING:
        return colors.yellow[500];

      case RequisitionStatus.CANCELLED:
        return colors.red[500];

      default:
        return colors.black;
    }
  };

  return (
    <Box pl={3}>
      <Stack direction="column">
        {allRequisitionsByStatus &&
          Object.entries(allRequisitionsByStatus)
            .sort()
            .map(([status, requisitions]) => (
              <Box key={status}>
                <Badge
                  color={colors.white}
                  background={getRequisitionColorFromStatus(status as RequisitionStatus)}
                  borderRadius="sm"
                  mb={2}
                >
                  <Text fontSize={14} fontWeight="bold">
                    {t(`requisition:${status}`)}
                  </Text>
                </Badge>
                {/* eslint-disable-next-line no-shadow */}
                {requisitions.map(({ requisitionId, status }) => (
                  <Flex key={requisitionId}>
                    <Checkbox
                      data-testid={`requisition_${requisitionId}`}
                      key={requisitionId}
                      isChecked={searchAndSortTypes.requisitionList.some(
                        (requisition) => requisition.requisitionId === requisitionId,
                      )}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                          searchAndSortTypes.requisitionList.push({ requisitionId, status });
                        } else {
                          const index = searchAndSortTypes.requisitionList.findIndex(
                            (requisition) => requisition.requisitionId === requisitionId,
                          );
                          searchAndSortTypes.requisitionList.splice(index, 1);
                        }
                        onChangeSearchAndSort(searchAndSortTypes);
                      }}
                    />
                    <Text
                      px={3}
                      fontSize={14}
                      fontWeight="normal"
                      textDecoration="underline"
                      color={colors.linkBlue}
                      cursor="pointer"
                      data-testid={`requisition_${requisitionId}_modal`}
                      onClick={() => handleRequisitionModalOpen(requisitionId)}
                    >
                      {requisitionId}
                    </Text>
                  </Flex>
                ))}
              </Box>
            ))}

        {!isUndefined(allRequisitionsByStatus) &&
          allRequisitionsByStatus &&
          Object.entries(allRequisitionsByStatus).length === 0 && (
            <Text color={colors.gray[500]} fontSize={14} data-testid="no-requisitions-message">
              {t(`requisition:noRequisitionAvailable`)}
            </Text>
          )}
      </Stack>

      {isOpenRequisition && (
        <ViewSingleRequisitionDetail
          isOpen={isOpenRequisition}
          onClose={onRequisitionModalClose}
          positionId={positionId}
          requisitionId={currentlyClickedRequisitionId}
          data-testid="ViewSingleRequisitionDetail"
        />
      )}
    </Box>
  );
};
