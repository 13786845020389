import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import React from 'react';
import { ConfirmMessageType, PositionFromPositionList } from '../PositionType';

export type UpdateStatusConfirmationProps = {
  isOpen: boolean;
  onClose: () => void;
  message: ConfirmMessageType;
  updatePositionByStatus: (position: PositionFromPositionList) => void;
  selectedPosition: PositionFromPositionList;
};

export const UpdateStatusConfirmation = ({
  isOpen,
  onClose,
  message,
  selectedPosition,
  updatePositionByStatus,
}: UpdateStatusConfirmationProps): JSX.Element => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{message.headerMessage}</ModalHeader>
          <ModalBody>{message.bodyMessage}</ModalBody>
          <ModalFooter mt={2}>
            <Button data-testid="ConfirmBtn" colorScheme="blue" mr={3} onClick={() => updatePositionByStatus(selectedPosition)}>
              {message.confirmText}
            </Button>
            <Button data-testid="CloseBtn" colorScheme="red" onClick={onClose}>
              {message.cancelText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
