import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestion } from '../PositionType';
import colors from '../../../../styles/colors';

export type SortableItemType = {
  item: MultipleChoiceQuestion;
  itemIndex: number;
  editMultipleChoiceQuestion: (index: number, item: MultipleChoiceQuestion) => void;
  setMultipleChoiceQuestionEditMode: (index: number | undefined) => void;
  setDeleteIndexMultipleChoice: (index: number | null) => void;
  deleteMultipleQuestionsQuestion: (index: number) => void;
  multipleChoiceQuestionEditMode: number | undefined;
  deleteIndexMultipleChoice: number | null;
};

export const SortableMultipleChoiceQuestionItem = SortableElement(
  ({
    item,
    itemIndex,
    editMultipleChoiceQuestion,
    setMultipleChoiceQuestionEditMode,
    setDeleteIndexMultipleChoice,
    deleteMultipleQuestionsQuestion,
    multipleChoiceQuestionEditMode,
    deleteIndexMultipleChoice,
  }: SortableItemType) => {
    const { t } = useTranslation();
    const { onOpen: onOpenMultipleChoice, onClose: onCloseMultipleChoice, isOpen: isOpenMultipleChoice } = useDisclosure();

    return (
      <li style={{ zIndex: 9999, cursor: 'move', marginTop: '15px', listStyle: 'none' }}>
        <Flex key={`${item.question}-${itemIndex}`} justifyContent="space-between">
          <Flex justifyContent="space-between" w="100%">
            <Box w="100%">
              <Flex>
                <Text fontSize="14px" fontWeight="700" pr="2px">
                  Q.
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  {item.question}

                  <span style={{ fontSize: '14px', fontWeight: 400, marginLeft: 6, fontStyle: 'italic' }}>
                    {`(${t(`createJobPosition:multipleChoiceQuestion:${item?.correctAnswerIf}`)})`}
                  </span>
                </Text>
              </Flex>
              <Box>
                <Flex w="100%">
                  <Text fontSize="12px" fontWeight="700" pr="2px">
                    A.
                  </Text>
                  <Flex w="100%" flexWrap="wrap">
                    {item.options.map((o, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Flex key={`${o.text}-${i}`} w="25%" alignItems="center">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {o.isPreferredAnswer ? (
                          item.totalAnswer === 1 ? (
                            <MdRadioButtonChecked style={{ color: colors.blue[500], marginTop: '3px' }} />
                          ) : (
                            <MdCheckBox style={{ color: colors.blue[500], marginTop: '3px' }} />
                          )
                        ) : item.totalAnswer === 1 ? (
                          <MdRadioButtonUnchecked style={{ color: colors.blue[500], marginTop: '3px' }} />
                        ) : (
                          <MdCheckBoxOutlineBlank style={{ color: colors.blue[500], marginTop: '3px' }} />
                        )}
                        <Text w="calc(100% - 25px)" ml="5px" fontSize="12px" lineHeight="1.3" fontWeight="400" mt="5px">
                          {o.text}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Flex>
          <Flex>
            <IconButton
              backgroundColor="#ECEFF1"
              aria-label="Edit Question"
              icon={<RiEdit2Fill style={{ color: colors.blue[500] }} />}
              data-testid={`EditQuestionBtn-${itemIndex}`}
              onClick={() => {
                /* istanbul ignore else */
                editMultipleChoiceQuestion(itemIndex, item);
                setMultipleChoiceQuestionEditMode(itemIndex);
              }}
            />
            <Box>
              <Popover
                returnFocusOnClose={false}
                isOpen={isOpenMultipleChoice && deleteIndexMultipleChoice === itemIndex}
                onClose={onCloseMultipleChoice}
                closeOnBlur
                isLazy
              >
                <PopoverTrigger>
                  <IconButton
                    backgroundColor="#ECEFF1"
                    ml={3}
                    aria-label="Delete Question"
                    icon={<RiDeleteBin5Fill style={{ color: colors.blue[500] }} />}
                    data-testid={`DeleteQuestionBtn-${itemIndex}`}
                    onClick={() => {
                      onOpenMultipleChoice();
                      if (itemIndex === multipleChoiceQuestionEditMode) setMultipleChoiceQuestionEditMode(undefined);
                      if (multipleChoiceQuestionEditMode && itemIndex < multipleChoiceQuestionEditMode)
                        setMultipleChoiceQuestionEditMode(multipleChoiceQuestionEditMode - 1);
                      setDeleteIndexMultipleChoice(itemIndex);
                    }}
                  />
                </PopoverTrigger>
                {isOpenMultipleChoice && (
                  <PopoverContent rootProps={{ style: { right: 0 } }}>
                    <PopoverBody fontWeight="semibold">{t('createJobPosition:deleteQuestionConfirmation')}</PopoverBody>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverFooter d="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button variant="outline" onClick={onCloseMultipleChoice}>
                          {t('common:questions.answerNegative')}
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            setDeleteIndexMultipleChoice(null);
                            deleteMultipleQuestionsQuestion(itemIndex);
                            onCloseMultipleChoice();
                          }}
                          data-testid={`deleteQuestionConfirmBtn-${itemIndex}`}
                        >
                          {t('common:questions.answerPositive')}
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          </Flex>
        </Flex>
      </li>
    );
  },
);
