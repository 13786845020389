export default {
  readyToJoin: 'Ready to join?',
  join: 'Join!',
  waitingForCandidate: 'Waiting for candidate',
  alreadyJoinedHeader: 'Interview already joined',
  alreadyJoinedBy: 'This interview is currently joined by',
  okay: 'Okay',
  ratingDisabledTooltip: 'Rating interview is only available upto 24 hours after the interview duration.',
  testSpeaker: 'Test Audio Output',
  yourDevices: 'Select the device you will use to listen to the interview',
  done: 'Done',
  checkYourDevice: 'Test Audio Output',
  guestMeet: {
    readyToJoin: `You're almost there!`,
    enterInfoToJoin: 'Fill in the fields below to join the interview',
    name: 'Your Name',
    namePlaceholder: 'Enter your full name',
    nameRequired: 'Your name is required',
    codeRequired: 'Code is required',
    code: 'Verification Code',
    codePlaceholder: 'Enter the verification code you were given',
    join: 'Join',
    hintMessageBeforeJoin:
      'In preparation for the interview, please make sure you are using the headphone or the device that you will use for the event.',
    joinAsLabel: 'You are logged in as {{userName}}.',
    joinMessage: 'You can now join the interview',
    joinMessagePreInterview: 'The interview will begin at {{time}}.',
    joinMessagePreInterviewOnAnotherDay: 'The interview will begin at {{day}}, {{time}}.',
    joinMessagePastInterview: 'Interview has been completed',
    invalidCode: 'Invalid Code',
    leaveCall: 'Leave Interview',
  },
  errorCode: {
    '1005': 'You cannot join the interview before its start time.',
    '1006': `You cannot join the interview after it's ended.`,
  },
  collaboratorFeatureNotAvailable: `Our co-interviewer feature is currently unavailable.`,
  timeEndingAlert: {
    giveAssessment: "Don't Forget to Assess The Candidate!",
    interviewTimeEnd: 'This interview will end soon.',
    timeRemaining: "This interview will end soon, so make sure to assess the candidate beforehand if you haven't already.\n",
    interviewEndAfter: 'This interview will automatically end in',
    okay: 'I understand',
  },
};
