import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Box, Heading, Text, Spinner } from '@chakra-ui/core';
import { CgArrowRight } from 'react-icons/cg';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Business } from '../administration/positionManagement/BusinessType';
import { PositionListResponse } from '../administration/positionManagement/PositionType';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { usePositionManagementContext } from '../administration/positionManagement/PositionManagementContext';

type PositionListForTopCandidateProps = {
  activeBusiness: Business;
  backToBusinessList: () => void;
  setScrollToBusiness: (business: Business) => void;
};

const PositionListForTopCandidate = ({
  activeBusiness,
  backToBusinessList,
  setScrollToBusiness,
}: PositionListForTopCandidateProps) => {
  const { t } = useTranslation('businessManagement');
  const defaultJobListResponse: PositionListResponse = {
    count: 0,
    lastPage: 1,
    to: 0,
    from: 0,
    data: [],
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  const [positionData, setPositionData] = useState<PositionListResponse>(defaultJobListResponse);
  const { positionQueryParams } = usePositionManagementContext();
  const { getPositionList } = useStoreActions((actions) => actions.positionManagement);
  const accountId = useStoreState((s) => s.app.user?.account);
  const loadPositionList = useCallback(
    async (business: Business) => {
      setLoading(true);
      // istanbul ignore next
      if (accountId && business.id) {
        const data: PositionListResponse = await getPositionList({
          accountId,
          businessId: business?.id,
          params: { ...positionQueryParams, limit: activeBusiness.positions <= 0 ? 1 : activeBusiness.positions },
        });
        setLoading(false);
        setPositionData(data);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, getPositionList, positionQueryParams],
  );

  useEffect(() => {
    loadPositionList(activeBusiness).catch((err) => console.log(err));
  }, [activeBusiness, loadPositionList]);

  return (
    <Box>
      <Box paddingLeft={0} paddingRight={0}>
        <Box borderBottom="0.5px solid #ECEFF1" px={8} py="1rem">
          <Flex
            data-testid="back-btn"
            onClick={() => {
              backToBusinessList();
              setScrollToBusiness(activeBusiness);
            }}
            color="blue.500"
            fontSize="18px"
            fontWeight="bold"
            alignItems="center"
          >
            <BiArrowBack style={{ marginRight: '5px' }} />
            {t('businessList.business')}
          </Flex>
        </Box>
      </Box>
      <Flex py={2} px={8} w="100%" alignItems="center" justifyContent="space-between">
        <Box maxW="70%">
          {activeBusiness.locationId && (
            <Text color="#607D8B" fontSize="14px" lineHeight={1.5}>{`LocationId: ${activeBusiness.locationId}`}</Text>
          )}
          <Heading as="h3" fontSize="16px" lineHeight={1.5}>
            {activeBusiness.name}
          </Heading>
          <Text color="#607D8B" fontSize="12px" lineHeight={1.5}>
            {activeBusiness.completeAddress}
          </Text>
        </Box>
        <Box>
          <Box bg="#F1F5F8" color="blue.500" py={2} px={8} fontSize="14px" fontWeight="bold">
            {`${activeBusiness.positions} ${activeBusiness.positions !== 1 ? t('businessList.jobs') : t('businessList.job')}`}
          </Box>
        </Box>
      </Flex>
      <Box py={4} px={8}>
        {isLoading && (
          <Flex h="120px" alignItems="center" justifyContent="center">
            <Spinner size="md" color="blue.500" />
          </Flex>
        )}
        {positionData.data.length > 0 && (
          <Flex flexWrap="wrap">
            {positionData.data.map((position, index) => (
              <Box
                key={position.id}
                w="calc(50% - 8px)"
                mr={index % 2 === 0 ? '16px' : 0}
                borderRadius="4px"
                border="1px solid #E5E7EB"
                mb={4}
              >
                <Link to={`/position/${position.id}`} style={{ padding: '8px', display: 'block' }}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>{`${index + 1}. ${position.customName}`}</Box>
                    <Box>
                      <CgArrowRight />
                    </Box>
                  </Flex>
                </Link>
              </Box>
            ))}
          </Flex>
        )}
        {!isLoading && positionData.data.length <= 0 && (
          <Flex p={3} alignItems="center" justifyContent="center">
            <Text fontSize="sm">No Positions !</Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default PositionListForTopCandidate;
