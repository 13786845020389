/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, BoxProps, Text, Stack, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Label } from '../../../../../common';
import SeekerEducationPresenter from '../seekerEducationPresenter';
import { EducationStatus, OTHER_DEGREE } from '../../../../../../firebase/firestore/documents/seeker';
import { getTranslatedValue } from '../../../../../../utils/localizedString';
import { Lookups } from '../../../../../../firebase/firestore/documents/lookups';

type EducationProps = BoxProps & {
  education: SeekerEducationPresenter;
  dataIndex: number;
  totalItems: number;
  educationLevels: Lookups[];
};

export const Education = ({
  education,
  dataIndex,
  totalItems,
  paddingTop,
  width,
  educationLevels,
}: EducationProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const showDate = education.status === EducationStatus.completed;

  const showOtherDegree = education.degree === OTHER_DEGREE;

  const degree = educationLevels.find((edu) => edu.id === education.degree);

  return (
    <Flex
      key={education.id}
      paddingTop={paddingTop}
      width={width}
      borderBottomWidth={dataIndex + 1 === totalItems ? 0 : '1px'}
      borderStyle="solid"
      borderColor="gray.50"
      py={2}
      px={2}
      align="center"
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      bg={dataIndex % 2 === 1 ? 'gray.150' : 'white'}
    >
      <Box>
        <Stack spacing={0} direction="row">
          <Label bold>
            {degree ? getTranslatedValue(degree.label, i18n.language) : education.degree}
            {showOtherDegree && ` - ${education.otherDegree}`}
          </Label>
        </Stack>
        <Text fontSize="xs">{education.fieldOfStudy}</Text>
        <Flex direction="row" justify="space-between" align="center" paddingTop={1}>
          <Text fontSize="xs" fontWeight="bold">
            {education.schoolName && education.schoolName}
          </Text>
        </Flex>
      </Box>
      <Text
        fontSize="xs"
        background="gray.50"
        color="#4A5568"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="10px"
        px={2}
        whiteSpace="nowrap"
      >
        {showDate && education.month && education.year ? `${t(`months.${education.month}`)}  ${education.year}` : null}
        {!showDate && t(`profile.education.${education.status}`)}
      </Text>
    </Flex>
  );
};
