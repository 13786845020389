/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Flex, Grid, Text, jsx, Image } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { RiHandCoinLine } from 'react-icons/ri';

import { BsCheckCircleFill } from 'react-icons/bs';
import { IoMdCalendar } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';
import isEmpty from 'lodash/isEmpty';
import AppyHereIcon from '../../../../../../assets/img/appy_logo.svg';
import Offer_Made_Icon from '../../../../../../assets/img/automated_icon/offer-made.svg';
import HelpRequestIcon from '../../../../../../assets/img/icon-help-request.svg';
import { CandidateHistoryActionName } from '../../../../../../firebase/firestore/documents/candidateAuditActionNames';
import colors from '../../../../../../styles/colors';
import { getTranslatedValue } from '../../../../../../utils/localizedString';
import useContactRequestDeclineReasons from '../../history/useContactRequestDeclinedReasons';
import { appointmentMessageCSS } from '../appointmentMessage/AppointmentMessage.style';
import { MessagePresenter } from '../messagePresenter';
import useLookupsByType from '../../../../../../app/hooks/useLookupsByType';

export type AppointmentMessageProps = {
  message: MessagePresenter;
};

export const OfferMadeMessages = ({ message, ...rest }: AppointmentMessageProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const declinedReasonsTypes = useContactRequestDeclineReasons();
  const offerRejectedReasons = useLookupsByType('offerRejectedReason');

  const revokeReason = (reasonValue: string | undefined) => {
    let reasonText;
    reasonText = declinedReasonsTypes.find((reason) => reason?.reasonType === reasonValue)?.text;
    if (isEmpty(reasonText)) {
      reasonText = offerRejectedReasons.find((reason) => reason.id === reasonValue)?.label;
    }
    return reasonText && getTranslatedValue(reasonText, i18n.language);
  };

  const getIconByAction = () => {
    if (message.action === CandidateHistoryActionName.OFFER_MADE) {
      return message.automation ? (
        <img src={Offer_Made_Icon} style={{ width: 40 }} alt="" />
      ) : (
        <RiHandCoinLine size={24} color={colors.blue.default} />
      );
    }
    if (message.action === CandidateHistoryActionName.OFFER_REVOKE) {
      return <img src={AppyHereIcon} style={{ height: 35, width: 'auto' }} alt="" />;
    }
    if (message.action === CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER) {
      return <BsCheckCircleFill size={24} color={colors.green[500]} />;
    }
    if (message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER) {
      return <MdCancel size={24} color={colors.red[500]} />;
    }
    if (message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_RECRUITER) {
      return <MdCancel size={24} color={colors.red[500]} />;
    }
    if (message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED) {
      return <IoMdCalendar size={24} color={colors.red[500]} />;
    }
    if (message.action === CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED) {
      return (
        <Image
          src={HelpRequestIcon}
          alt="help request"
          style={{ fontSize: '24px', color: colors.blue['500'], marginRight: '5px', height: '24px' }}
        />
      );
    }

    return <Box />;
  };

  const getReason = () => {
    if (
      message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER ||
      message.action === CandidateHistoryActionName.OFFER_REVOKE
    ) {
      return (
        <Text fontSize="sm" color="gray.400" fontWeight="bold">
          {message.reasonId === 'OTHER'
            ? `${revokeReason(message.reasonId)} - ${message.reasonComment}`
            : revokeReason(message.reasonId)}
        </Text>
      );
    }
    return false;
  };

  return (
    <Box
      css={appointmentMessageCSS}
      data-testid="AppointmentMessageBox"
      backgroundColor="#ffffff"
      className={message.isFromSeeker ? 'is-pin-left' : 'is-pin-right'}
      borderColor={`${
        message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED ? colors.red[500] : colors.blue[500]
      } !important`}
      {...rest}
    >
      <Flex paddingLeft="10px" paddingTop="10px" paddingBottom="10px">
        {getIconByAction()}
        <Grid width="100%">
          <Text
            paddingLeft={3}
            paddingRight={3}
            color={message.action === CandidateHistoryActionName.OFFER_MADE ? colors.blue.default : colors.gray[700]}
            lineHeight={message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED ? 'inherit' : 'shorter'}
          >
            {t(`candidateHistory:${message.action}`)}
            {getReason()}
          </Text>
          {message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_RECRUITER && message.content && (
            <Text paddingLeft={3} paddingRight={3} color="gray.400" fontWeight="bold">
              {message.content}
            </Text>
          )}
          {message.action !== CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED && (
            <Text paddingLeft={3} paddingRight={3} className="appointmentDate" css={appointmentMessageCSS}>
              {message.timestamp && t('appointmentMessages:APPOINTMENT_MESSAGE.APPOINTMENT_DATE', { date: message.timestamp })}
            </Text>
          )}
        </Grid>
      </Flex>
    </Box>
  );
};
