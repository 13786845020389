import React, { useEffect, useState } from 'react';
import { AvatarBadge, Box, Flex, Text, Popover, PopoverTrigger, PopoverContent, PopoverBody } from '@chakra-ui/core';
import { AiOutlineLike, AiFillLike } from 'react-icons/ai';
import moment from 'moment-timezone';
import { BsBookmarkStar } from 'react-icons/bs';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { MdOutlineAccountBox } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { ProfilePicture } from '../../common';
import colors from '../../../styles/colors';
import { useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { useTopCandidatesContext } from './TopCandidatesContext';
import SeekerExperiencePresenter from '../../messaging/seeker/candidate/profile/seekerExperiencePresenter';
import SeekerEducationPresenter from '../../messaging/seeker/candidate/profile/seekerEducationPresenter';
import { Experience } from '../../messaging/seeker/candidate/profile/components/Experience';
import { Education } from '../../messaging/seeker/candidate/profile/components/Education';
import useEducationLevels from '../../../app/hooks/useEducationLevels';
import { TopCandidateDataType } from './TopCandidateDataType';
import { getTranslatedValue } from '../../../utils/localizedString';

export type CandidateViewProps = {
  topCandidate: TopCandidateDataType;
  handleContactRequest: (selectedCandidate: TopCandidateDataType) => void;
};
export const CandidateView = ({ topCandidate, handleContactRequest }: CandidateViewProps): JSX.Element => {
  const [profileURL, setProfileURl] = useState<string>();
  const { t, i18n } = useTranslation();
  const educationLevels = useEducationLevels();
  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);

  const recentlyActive =
    moment(topCandidate.seeker.lastActivity).toDate() > moment().subtract(minutesBeforeInactivity, 'minute').toDate();
  const seekersProfilePictures = useStoreState((state) => state.messaging.seekersProfilePictures);
  const { selectedTopCandidate, setSelectedTopCandidate } = useTopCandidatesContext();
  const candidateExperiences = (
    topCandidate.seeker?.experiences?.map((experience) => new SeekerExperiencePresenter(experience)) ?? []
  ).sort((x, y) => Number(y.current) - Number(x.current));
  const candidateEducations = topCandidate.seeker?.educations?.map((education) => new SeekerEducationPresenter(education)) ?? [];
  const degree = educationLevels.find((edu) => edu.id === topCandidate.education);

  useEffect(() => {
    const url = seekersProfilePictures[topCandidate.seeker.id];
    setProfileURl(url);
  }, [topCandidate, seekersProfilePictures]);

  return (
    <Flex
      alignItems="center"
      background={selectedTopCandidate?.seeker?.id === topCandidate.seeker.id ? '#2FA1DA1A' : colors.neutral[50]}
      p="16px"
      borderRadius="8px"
      mt="12px"
      mr="12px"
      ml="12px"
      cursor="pointer"
      _hover={{
        transition: '0.09s ease-in transform',
      }}
      onClick={() => {
        setSelectedTopCandidate(topCandidate);
      }}
    >
      <Flex w="33%" alignItems="center">
        {topCandidate.hasApplied ? (
          <AiFillLike color={colors.blue['500']} size="24px" />
        ) : (
          <AiOutlineLike onClick={() => handleContactRequest(topCandidate)} size="24px" />
        )}
        <ProfilePicture
          ml="10px"
          src={profileURL}
          style={{ backgroundColor: colors.orange[500], color: 'white' }}
          name={`${topCandidate.seeker.firstName} ${topCandidate.seeker.lastName}`}
          bg="transparent"
        >
          {recentlyActive && <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />}
        </ProfilePicture>
        <Box ml="20px">
          <Flex alignItems="center">
            <Text fontWeight={700} fontSize="16px" mr="4px">
              {`${topCandidate.seeker.firstName} ${topCandidate.seeker.lastName}`}
            </Text>
            <BsBookmarkStar />
            {topCandidate.hasApplied && (
              <Box
                background="#C6F6D5"
                color="#22543D"
                fontWeight={700}
                fontSize="10px"
                h="fit-content"
                borderRadius="14px"
                p="2px 6px"
                ml="4px"
              >
                {t('businessManagement:topCandidate.hasApplied')}
              </Box>
            )}
          </Flex>
          <Flex alignItems="center">
            <RiMoneyDollarCircleFill color={colors.green[700]} size="16px" />
            <Text fontSize="14px" fontWeight={400} color={colors.neutral[600]} ml="12px">
              {topCandidate.withinRange
                ? t('businessManagement:topCandidate.withInRange')
                : t('businessManagement:topCandidate.aboveRange')}
            </Text>
          </Flex>
          <Text fontSize="14px" fontWeight={400} color={colors.neutral[600]}>
            {`${t('businessManagement:topCandidate.lastActive')} : ${moment(topCandidate.seeker.lastActivity)
              .locale(i18n.language)
              .fromNow()}`}
          </Text>
        </Box>
      </Flex>
      <Box pl="30px" w="33%">
        <Flex>
          <MdOutlineAccountBox size="20px" />
          <Text fontSize="14px" fontWeight="700" ml="12px">
            {t('common:profile.experience.title')}
          </Text>
        </Flex>
        <Popover trigger="hover">
          <PopoverTrigger>
            <Box
              mt="8px"
              fontSize="14px"
              fontWeight={400}
              background={colors.neutral[100]}
              p="8px"
              borderRadius="14px"
              color-={colors.neutral[600]}
            >
              {topCandidate.workExperience > 10 && `More than 10 years of direct experience`}
              {topCandidate.workExperience < 10 &&
                topCandidate.workExperience > 0 &&
                `${topCandidate.workExperience} years of direct experience`}
              {topCandidate.workExperience === 0 && `--`}
            </Box>
          </PopoverTrigger>
          {candidateExperiences.length > 0 && (
            <PopoverContent border="1px solid #E5E7EB !important" w="380px">
              <PopoverBody>
                {candidateExperiences.map((experience, index) => (
                  <Experience
                    key={experience.id}
                    experience={experience}
                    totalItems={candidateExperiences.length}
                    dataIndex={index}
                  />
                ))}
              </PopoverBody>
            </PopoverContent>
          )}
        </Popover>
      </Box>
      <Box pl="30px" w="33%">
        <Flex>
          <MdOutlineAccountBox size="20px" />
          <Text fontSize="14px" fontWeight="700" ml="12px">
            {t('common:profile.education.title')}
          </Text>
        </Flex>
        {topCandidate.education ? (
          <Popover trigger="hover">
            <PopoverTrigger>
              <Box
                mt="8px"
                fontSize="14px"
                fontWeight={400}
                background={colors.neutral[100]}
                p="8px"
                borderRadius="14px"
                color-={colors.neutral[600]}
              >
                {degree ? getTranslatedValue(degree.label, i18n.language) : topCandidate.education}
              </Box>
            </PopoverTrigger>
            <PopoverContent border="1px solid #E5E7EB !important">
              <PopoverBody>
                {candidateEducations.length > 0 &&
                  candidateEducations.map((education, index) => (
                    <Education
                      key={education.id}
                      dataIndex={index}
                      education={education}
                      totalItems={candidateEducations.length}
                      educationLevels={educationLevels}
                    />
                  ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Box
            mt="8px"
            fontSize="14px"
            fontWeight={400}
            background={colors.neutral[100]}
            p="8px"
            borderRadius="14px"
            color-={colors.neutral[600]}
          >
            {!topCandidate.education && `--`}
          </Box>
        )}
      </Box>
    </Flex>
  );
};
