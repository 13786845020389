export default {
  updates: 'Updates',
  notifications: 'Notifications',
  invitation: 'Invitation',
  accept: 'Accept',
  decline: 'Decline',
  noNotification: 'No Notifications!',
  inPastLabel: 'In the past',
  noNewsDescription: 'No news.',
  accepted: 'Accepted',
  declined: 'Declined',
  cancelled: 'Cancelled',
  requested: 'Requested',
  acceptedWithMessage: 'Reschedule',
  revoked: 'Revoked',
  noDataFound: 'No data found.',
  otherRecruitersAppointment: 'This interview is scheduled with {{otherRecruiter}}',

  invitationConfirmationModal: {
    headerText: 'Are you sure you want to {{invitationAction}} ?',
    bodyText: '',
    cancelButton: 'Cancel',
    confirmButton: 'Confirm',
  },

  subTabs: {
    groupEvent: 'Group Event',
    activities: 'Activities',
    backgroundCheck: 'Background Check',
    candidates: 'Candidates',
    hireForm: 'Documents',
    modernHireForm: 'Competency Evaluation',
    requisition: 'Requisition',
    languageEvaluation: 'Language Evaluation',
  },
  statusIcon: {
    expired: 'Expired',
    inactive: 'Inactive',
    appointmentInThePast: 'Interview - In the past',
    positionClosed: 'Closed Position',
    closed: 'Closed',
    appointmentStatusAccepted: 'Interview - Accepted',
    appointmentStatusRequested: 'Interview - Requested',
    appointmentStatusDeclined: 'Interview - Declined',
    appointmentStatusCancelled: 'Interview - Cancelled',
    appointmentStatusReschedulingNeeded: 'Interview - Reschedule',
    contactStatusAccepted: 'Contact - Accepted',
    contactStatusDeclined: 'Contact - Declined',
    contactStatusSent: 'Contact - Requested',
    interestRevoked: 'Not interested',
    seekerIsHired: 'Hired',
    seekerIsNotLookingForWork: 'Currently working',
    appointmentDateFormat: 'MMM. Do h:mm A',
    hoursToDismissal: {
      zero: 'In a few minutes',
      one: 'In one hour',
      other: 'In {{count}} hours',
    },
    joinVideo: 'STARTED',
  },
  availability: {
    noAvailability: {
      title: 'No Schedule Availability',
      description: 'You currently have no schedule availability. You can create some using the "Create" option.',
    },
    lowAvailability: {
      title: 'Low Schedule Availability',
      description:
        "You have {{candidates}} candidates waiting to schedule  interviews, but your current availabilities can't accomodate these candidates.",
    },
    alert: {
      lowAvailability: `<strong>Increase Schedule Availability</strong> - {{candidates}} candidates pending`,
      lowAvailabilityNoCount: '<b>Low Schedule Availability</b> - Please increase your availability',
    },
  },
  vacancyStatusReminderModal: {
    addVacancy: 'Add more openings',
    closePosition: 'Close postion',
    okay: 'Ok',
    actionSuccessful: 'Action successful',
    actionSuccessfulDescription: 'Position successfully closed',
  },
  addVacancyModal: {
    addNoOfOpenings: 'Enter the number of additional openings for this position',
    actionSuccessfulDescription: 'Number of openings updated',
    alreadyUpdated: 'This position has already been updated',
  },
  seekerHeader: {
    actionSuccessFul: 'Action successful',
    addedToFavorite: 'Candidate added to favorite list',
    removedFromFavorite: 'Candidate removed from favorite list',
    favoriteTooltip: 'Add to favorite',
    unFavoriteTooltip: 'Remove from favorite',
    favoriteCandidate: 'Favorite Candidate',
    deactivatedUserLabel: 'Deactivated Account',
    deactivatedFavoriteText: 'You cannot favorite a deactivated account.',
    priorityTooltip: 'This is a referred candidate.',
    kioskTooltip: 'This candidate has applied using Kiosk Mode',
    interventionTooltip: 'Recruiter Intervention Required',
    candidateURLCopyTooltip: 'Click to copy candidate url',
    urlCopiedInfo: 'Copied Candidate URL',
  },
  certn: {
    title: `<b>{{candidate}}'s</b> background check completed`,
  },
};
